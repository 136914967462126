import React, {useEffect} from "react";
import "../../styles/CalendarView.css";
import { faCalendar, faClockRotateLeft, faMugHot } from '@fortawesome/free-solid-svg-icons';

const CalendarHeaderPrintable = ({
    name,
    employment_number,
    numberOfReports = 0,
    totalHours = "0:00h",
    totalBreak = "0:00h",
    travelExpenses = 0,
    allowance = 0,
    exclamation = 0,
    overtime16_17 = 0,
    overtime17_19 = 0,
    overtime19_22 = 0,
    overtime22_05 = 0,
}) => {

    const colourStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "#F2F5FA",
            border: "none",
            borderRadius: "10px",
            height: "51px",
            width: "259px",
            margin: "5px",
        }),
    };

    return (
        <>
            <div className="subHeaderContainerCalendar">
                <div className="userFilter">
                    <h4>{name}</h4>
                    <p>Anställningsnummer: {employment_number}</p>
                    
                </div>
                <div className="informationBlock">
                    <div className="informationContainer">
                        <div className="detailedInfoContainer">
                            <p className="infoHeaderText">Antal rapporter:</p>
                            <p className="infoHeaderText">Timmar:</p>
                            <p className="infoHeaderText"></p>
                        </div>
                        <div className="detailedDataContainer">
                            <p className="infoText">{numberOfReports}</p>
                            <p className="infoText">{totalHours}</p>
                            <p className="infoText"></p>
                        </div>
                    </div>
                    <div className="informationContainer">
                        <div className="detailedInfoContainer">
                            <p className="infoHeaderText">Reseersättning:</p>
                            <p className="infoHeaderText">Utryckning:</p>
                            <p className="infoHeaderText">Traktamente:</p>
                        </div>
                        <div className="detailedDataContainer">
                            <p className="infoText">{travelExpenses}</p>
                            <p className="infoText">{exclamation}</p>
                            <p className="infoText">{allowance}</p>
                        </div>
                    </div>
                    <div className="informationContainer">
                        <div className="detailedInfoContainer">
                            <p className="infoHeaderText">ÖT 16-17:</p>
                            <p className="infoHeaderText">ÖT 17-19:</p>
                        </div>
                        <div className="detailedDataContainer">
                            <p className="infoText">{overtime16_17}</p>
                            <p className="infoText">{overtime17_19}</p>
                        </div>
                    </div>
                    <div className="informationContainer">
                        <div className="detailedInfoContainer">
                            <p className="infoHeaderText">ÖT 19-22:</p>
                            <p className="infoHeaderText">ÖT 22-05:</p>
                        </div>
                        <div className="detailedDataContainer">
                            <p className="infoText">{overtime19_22}</p>
                            <p className="infoText">{overtime22_05}</p>
                        </div>
                    </div>
                </div>
</div>


</>
    )
}

export default CalendarHeaderPrintable;