const handleSickLeaveAndVABHours = (week, checkAbsence) => {
    // Iterera över alla entries i checkAbsence
    for (const entry of checkAbsence.entries) {
        // Kontrollera om det är Sjukfrånvaro eller VAB
        if (entry.absence_type === "Sjukfrånvaro" || entry.absence_type === "VAB") {
            var absenceDate = entry.date.slice(0,10); // Extrahera datum

            // Leta efter matchande entry i week baserat på datum och anställningsnummer
            var matchingWeekEntry = week.entries.find(weekEntry => {
                const weekDate = weekEntry.date.slice(0,10);
                return weekDate === absenceDate && week.employment_number === checkAbsence.employment_number;
            });

            var matchingAbsenceEntries = checkAbsence.entries.filter(absenceEntry => {
                const absenceEntryDate = absenceEntry.date.slice(0,10);
                return absenceEntryDate === absenceDate;
            });
            

            if (matchingWeekEntry || matchingAbsenceEntries.length > 1) {
                // Gör ingenting
            } else {
                // Sätt antalet timmar till 8h om den totala sjuktiden är mindre än 8h
                if (entry.absence_hours < 8) {
                    entry.absence_hours = 8;
                }
            }
        }
    }
    return checkAbsence;
};

export default handleSickLeaveAndVABHours;
