import { useState } from "react";
import structureDataForExport from "./exportUtils/structureDataForExport";
import structureAbsenceForExport from "./exportUtils/structureAbsenceForExport";

export function useExportXml() {
  const [xml, setXml] = useState(null);
  const todayIs = new Date().toISOString().slice(0, 10);
  const activeUser = localStorage.getItem("loggedInUser");
  async function exportXml(data, entireWeekData, startDate, endDate, isLoading, setIsLoading, usersList) {
    let structuredData;
    if (data[0].work_hours) {
      structuredData = await structureDataForExport(data, entireWeekData, startDate, endDate, usersList);

      const xmlData = `<?xml version="1.0" encoding="ISO-8859-1"?>
      <PayRoll xmlns="http://www.hogia.se/XMLSchemas/pa" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
        <FileInfo>
          <companyName>Samuraj2</companyName>
          <softwareProduct>Tidig</softwareProduct>
          <createdBy>${activeUser}</createdBy>
          <createdDate>${todayIs}</createdDate>
        </FileInfo>
        ${structuredData
          .map(
            (data) =>
              `<PayTypeInstruction>
          <employmentId>${data.employmentId}</employmentId>
          <payTypeCode>${data.payTypeCode}</payTypeCode>
          <payTypeId>${data.payTypeId}</payTypeId>
          <quantity>${data.quantity}</quantity>
          <periodStartDate>${data.periodStartDate}</periodStartDate>
          ${data.periodStartTime ? `<periodStartTime>${data.periodStartTime}</periodStartTime>` : ""}
          <periodEndDate>${data.periodEndDate}</periodEndDate>
          ${data.periodEndTime ? `<periodEndTime>${data.periodEndTime}</periodEndTime>` : ""}
          ${data.extent ? `<extent>${data.extent}</extent>` : ""}
          ${data.costCenter ? `<account>${data.costCenter}</account>` : ""}
          </PayTypeInstruction>\n`
          )
          .join("")}
      </PayRoll>`;
      const blob = new Blob([xmlData], { type: "text/xml;charset=utf-8" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${todayIs} Tidig Tidsrapportexport.xml`;
      document.body.appendChild(link);
      link.click();
      link.remove();
      setIsLoading(false);
    } else {
      structuredData = await structureAbsenceForExport(data);
      const xmlData = `<?xml version="1.0" encoding="ISO-8859-1"?>
      <PayRoll xmlns="http://www.hogia.se/XMLSchemas/pa" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
        <FileInfo>
          <companyName>Samuraj2</companyName>
          <softwareProduct>Tidig</softwareProduct>
          <createdBy>${activeUser}</createdBy>
          <createdDate>${todayIs}</createdDate>
        </FileInfo>
        ${structuredData
          .map(
            (data) =>
              `<PayTypeInstruction>
          <employmentId>${data.employmentId}</employmentId>
          <payTypeCode>${data.payTypeCode}</payTypeCode>
          <payTypeId>${data.payTypeId}</payTypeId>
          <quantity>${data.quantity}</quantity>
          <periodStartDate>${data.periodStartDate}</periodStartDate>
          <periodEndDate>${data.periodEndDate}</periodEndDate>
          </PayTypeInstruction>\n`
          )
          .join("")}
      </PayRoll>`;
      const blob = new Blob([xmlData], { type: "text/xml;charset=utf-8" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${todayIs} Tidig Frånvaroexport.xml`;
      document.body.appendChild(link);
      link.click();
      link.remove();
      setIsLoading(false);

    }
  }

  return { xml, exportXml };
}
