const isASingleUserSelectedForExport = (data) => {
    // Check if the data array is empty, return false
    if (data.length === 0) {
        return false;
    }

    let tempUserId = data[0].user_id; // Set the initial user_id from the first item

    for (let i = 1; i < data.length; i++) {  // Start from the second item
        if (data[i].user_id !== tempUserId) {
            return false; // If any user_id is different, return false
        }
    }

    return true; // If all user_id are the same, return true
};

export default isASingleUserSelectedForExport;
