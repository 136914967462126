import checkForWeekendEntries from "./checkForWeekendEntries";
import getWeekDates from "./returnPeriodDates";
import moment from "moment";

const getAllWeeksBetweenDates = (startDate, endDate) => {
  const weeks = [];
  let current = moment(startDate);
  const end = moment(endDate);

  while (current <= end) {
    weeks.push({
      year: current.year(),
      week: current.week(),
    });
    current = current.add(1, "weeks");
  }

  return weeks;
};

const generateDefaultWeekObject = ({
  year,
  week,
  user_id,
  user_name,
  employment_type,
  employment_number,
}) => {
  const start_date = moment()
    .year(year)
    .isoWeek(week)
    .startOf("isoWeek")
    .format("YYYY-MM-DD");
  const end_date = moment()
    .year(year)
    .isoWeek(week)
    .endOf("isoWeek")
    .format("YYYY-MM-DD");

  return {
    year,
    week,
    user_id,
    user_name,
    total_work_hours: 0,
    total_comp_hours: 0,
    total_actual_work_hours: 0,
    total_holiday_hours: 0,
    total_weekend_hours: 0,
    total_reported_hours: 0,
    comp_30: 0,
    comp_50: 0,
    comp_70: 0,
    comp_100: 0,
    start_date,
    end_date,
    employment_type,
    employment_number,
    entries: [],
    out_call: 0,
    has_travel_expences: 0,
    has_allowance: 0,
    holidays: [],
    weekEnds: [],
    total_work_hours_outside_span: 0,
    mock: 1,
  };
};

// Normalize date to YYYY-MM-DD
const normalizeDate = (dateString) => {
  return dateString.slice(0, 10);
};

export const groupDataByWeek = (
  data,
  holidayDates,
  entireWeekData,
  startDate,
  endDate
) => {

  // Ensure that all dates are on the same format YYYY-MM-DD
  const normalizedEntireWeekData = entireWeekData.map((entry) => {
    return { ...entry, date: normalizeDate(entry.date) };
  });


  // Build the data structure by using the reducer function
  const groupedData = data.reduce((acc, curr) => {
    // Skip entries where week is 99
    if (curr.week == '99') {
      return acc;
    }
    const first = getWeekDates(curr.date).first;
    const last = getWeekDates(curr.date).last;
      
    const key = curr.employment_number + "-" + curr.week + "-" + curr.year;
    if (!acc[key]) {
      acc[key] = {
        year: curr.year,
        week: curr.week,
        user_id: parseInt(curr.user_id, 10),
        user_name: curr.user_name,
        total_work_hours: 0,
        total_comp_hours: 0,
        total_actual_work_hours: 0,
        total_holiday_hours: 0,
        total_weekend_hours: 0,
        total_reported_hours: 0,
        comp_30: 0,
        comp_50: 0,
        comp_70: 0,
        comp_100: 0,
        start_date: null,
        end_date: null,
        employment_type: curr.employment_type,
        employment_number: curr.employment_number,
        entries: [],
        out_call: 0,
        has_travel_expences: 0,
        has_allowance: 0,
        holidays: [],
        weekEnds: [],
        total_work_hours_outside_span: 0,
      };

      const currentDate = normalizeDate(curr.date);

      if (!acc[key].start_date || currentDate < acc[key].start_date) {
        acc[key].start_date = first;
      }

      if (!acc[key].end_date || currentDate > acc[key].end_date) {
        acc[key].end_date = last;
      }
    }

    let actual_work_hours =
      parseFloat(curr.work_hours) -
      (parseFloat(curr.comp_30) +
        parseFloat(curr.comp_50) +
        parseFloat(curr.comp_70) +
        parseFloat(curr.comp_100));

    if (holidayDates.includes(curr.date)) {
      acc[key].holidays.push({
        id: curr.id,
        date: curr.date.slice(0, 10),
        start_time: curr.start_time,
        stop_time: curr.stop_time,
        work_hours: parseFloat(curr.work_hours),
        actual_work_hours: actual_work_hours,
        comp_30: parseFloat(curr.comp_30),
        comp_50: parseFloat(curr.comp_50),
        comp_70: parseFloat(curr.comp_70),
        comp_100: parseFloat(curr.comp_100),
        out_call: curr.out_call,
        has_travel_expences: curr.has_travel_expences,
        has_allowance: curr.has_allowance,
        compensation_type: curr.compensation_type,
      });

      acc[key].total_holiday_hours += parseFloat(curr.work_hours);
      acc[key].total_actual_work_hours += actual_work_hours;
    } else if (checkForWeekendEntries(curr.date)) {
      acc[key].weekEnds.push({
        id: curr.id,
        date: curr.date.slice(0, 10),
        start_time: curr.start_time,
        stop_time: curr.stop_time,
        work_hours: parseFloat(curr.work_hours),
        actual_work_hours: actual_work_hours,
        comp_30: parseFloat(curr.comp_30),
        comp_50: parseFloat(curr.comp_50),
        comp_70: parseFloat(curr.comp_70),
        comp_100: parseFloat(curr.comp_100),
        out_call: curr.out_call,
        has_travel_expences: curr.has_travel_expences,
        has_allowance: curr.has_allowance,
        compensation_type: curr.compensation_type,
      });

      acc[key].total_weekend_hours += parseFloat(curr.work_hours);
      acc[key].total_actual_work_hours += actual_work_hours;
    } else {
      acc[key].entries.push({
        id: curr.id,
        date: curr.date.slice(0, 10),
        start_time: curr.start_time,
        stop_time: curr.stop_time,
        work_hours: parseFloat(curr.work_hours),
        actual_work_hours: actual_work_hours,
        comp_30: parseFloat(curr.comp_30),
        comp_50: parseFloat(curr.comp_50),
        comp_70: parseFloat(curr.comp_70),
        comp_100: parseFloat(curr.comp_100),
        out_call: curr.out_call,
        has_travel_expences: curr.has_travel_expences,
        has_allowance: curr.has_allowance,
        compensation_type: curr.compensation_type,
      });

      acc[key].total_work_hours += parseFloat(curr.work_hours);
      acc[key].total_actual_work_hours += actual_work_hours;
    }

    acc[key].total_comp_hours +=
      parseFloat(curr.comp_30) +
      parseFloat(curr.comp_50) +
      parseFloat(curr.comp_70) +
      parseFloat(curr.comp_100);

    acc[key].comp_30 += parseFloat(curr.comp_30);
    acc[key].comp_50 += parseFloat(curr.comp_50);
    acc[key].comp_70 += parseFloat(curr.comp_70);
    acc[key].comp_100 += parseFloat(curr.comp_100);
    acc[key].out_call += parseFloat(curr.out_call) || 0;
    acc[key].has_travel_expences += parseFloat(curr.has_travel_expences) || 0;
    acc[key].has_allowance += parseFloat(curr.has_allowance) || 0;

    return acc;
  }, {});

  let total_work_hours_outside_span = 0;
  normalizedEntireWeekData.forEach((entry) => {
    const year = entry.year;
    const week = entry.week;
    const matchingWeek = Object.values(groupedData).find((weekData) => {
      return (
        weekData.year === year &&
        weekData.week === week &&
        weekData.employment_number === entry.employment_number
      );
    });

    if (matchingWeek) {
      matchingWeek.total_work_hours_outside_span += parseFloat(
        entry.work_hours
      );
      total_work_hours_outside_span += parseFloat(entry.work_hours);
    }
  });

  const allWeeks = getAllWeeksBetweenDates(startDate, endDate);

  allWeeks.forEach(({ year, week }) => {
    data.forEach((curr) => {
      const key = `${curr.employment_number}-${week}-${year}`;

      if (!groupedData[key]) {
        const defaultWeekObj = generateDefaultWeekObject({
          year,
          week,
          user_id: parseInt(curr.user_id, 10),
          user_name: curr.user_name,
          employment_type: curr.employment_type,
          employment_number: curr.employment_number,
        });

        groupedData[key] = defaultWeekObj;
      }
    });
  });
  return Object.values(groupedData);
};
