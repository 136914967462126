import isDateAHoliday from "./holidays";

async function convertToXmlStructure(objects) {
  
  const compiledObjects = [];

  const restructureData = [];
  for await (const week of objects) {
    week.entries.forEach((element) => {
      const getIdCodeCostCenter = getPayTypeIdAndCostCenter(
        element.absence_type,
        week.employment_type
      );
      const payTypeId = getIdCodeCostCenter.payTypeId;
      const costCenter = getIdCodeCostCenter.costCenter;
      const payTypeCode = getIdCodeCostCenter.payTypeCode;
      
      // If the absence type is "Bet. Semester" then we need to set the absence hours to 1.
      // We should also remove any vacation entry that has a date that is on a weekend. Or a date that is a holiday.
      const isPaidVacation = element.absence_type === "Bet. Semester";
      const isWeekend = new Date(element.date).getDay() === 6 || new Date(element.date).getDay() === 0; // Check if the day is saturday or sunday
      const isHoliday = isDateAHoliday(element.date.slice(0, 10));
      if (isPaidVacation) {
        element.absence_hours = 1;
        if (isWeekend || isHoliday) return;
      } 
        restructureData.push({
          periodStartDate: element.date.slice(0, 10),
          periodStartTime: element.start_time,
          periodEndDate: element.date.slice(0, 10),
          periodEndTime: element.stop_time,
          quantity: element.absence_hours,
          employmentId: week.employment_number,
          payTypeCode: payTypeCode,
          payTypeId: payTypeId,
          // costCenter: costCenter,
        });
      
      
    });
  }
  return restructureData;
}

function getPayTypeIdAndCostCenter(absenceType, employmentType) {
  switch (absenceType) {
    // Check
    case "ATF":
      if (employmentType === "month")
        return { payTypeId: 406, payTypeCode: "L" };
      else if (employmentType === "hour")
        return { payTypeId: 407, payTypeCode: "L" };
      else throw new Error(`Invalid employment type: ${employmentType}`);

    // Check
    case "Sjukfrånvaro":
      return { payTypeId: 360, payTypeCode: "A" };

    // Check
    case "Bet. Semester":
      if (employmentType === "month")
        return { payTypeId: 510, payTypeCode: "L" };
      else if (employmentType === "hour")
        return { payTypeId: 525, payTypeCode: "L" };
      else throw new Error(`Invalid employment type: ${employmentType}`);

    // Check
    case "OBet. Semester":
      if (employmentType === "month")
        return { payTypeId: 503, payTypeCode: "L" };
      else if (employmentType === "hour")
        return { payTypeId: 507, payTypeCode: "L" };
      else throw new Error(`Invalid employment type: ${employmentType}`);

    // måste skrivas logik kring månadslön. Olika lönetyper beroende på antal dagar/timmar eller hel månad
    case "Tjänsteledighet":
      if (employmentType === "month")
        return { payTypeId: 620, payTypeCode: "L" };
      else if (employmentType === "hour")
        return { payTypeId: 627, payTypeCode: "L" };
      else throw new Error(`Invalid employment type: ${employmentType}`);

    // Check
    case "Permission":
      if (employmentType === "month")
        return { payTypeId: 650, payTypeCode: "L" };
      else if (employmentType === "hour")
        return { payTypeId: 627, payTypeCode: "L" };
      else throw new Error(`Invalid employment type: ${employmentType}`);

    // flera olika lönearter, behöver kolla upp och skriva logik. Fick inga detaljer, bara att det är 659-662 för tim och 664-665 för månadslön
    case "Föräldraledighet":
      if (employmentType === "month")
        return { payTypeId: 629, payTypeCode: "L" };
      else if (employmentType === "hour")
        return { payTypeId: 629, payTypeCode: "L" };

    // Check
    case "VAB":
      if (employmentType === "month")
        return { payTypeId: 666, costCenter: 7210, payTypeCode: "L" };
      else if (employmentType === "hour")
        return { payTypeId: 655, payTypeCode: "L" };

    // Check
    case "Uttag av komp.tid":
      return { payTypeId: 860, payTypeCode: "L" };

    // måste skrivas logik kring månadslön. Olika lönetyper beroende på antal dagar/timmar eller hel månad. Samma som tjänsteldighet.
    case "Otillåten frånvaro":
      if (employmentType === "month")
        return { payTypeId: 620, payTypeCode: "L" };
      else if (employmentType === "hour")
        return { payTypeId: 627, payTypeCode: "L" };
      else throw new Error(`Invalid employment type: ${employmentType}`);

    default:
      throw new Error(`Invalid absence type: ${absenceType}`);
  }
}


export default convertToXmlStructure;
