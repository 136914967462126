const createEmptyWeekDataForMonthEmploymentUsers = (monthEmploymentUsers, startDate, endDate) => {
    const emptyWeekDataForMonthEmploymentUsers = [];
    monthEmploymentUsers.forEach((user) => {
        const { id: user_id, employee_employment } = user;
        const userWeekData = [];
        userWeekData.push({
            note: null,
            id: null,
            user_id: user.value,
            start_time: "07:00",
            stop_time: "07:00",
            work_hours: "0",
            date: startDate.toISOString().slice(0, 10),
            week: "99",
            year: "2024",
            has_allowance: "0",
            has_travel_expences: "0",
            out_call: "0",
            comp_30: "0",
            comp_50: "0",
            comp_70: "0",
            comp_100: "0",
            break_time: "0",
            creation_date: null,
            last_updated: null,
            editable: "0",
            certified: "0",
            certifier: null,
            city: null,
            certified_date: null,
            employment_number: user.employee_number,
            employment_type: user.employee_employment,
            export: "0",
            exported: "0",
            compensation_type: "Salary",
            user_name: user.label,
        });
        emptyWeekDataForMonthEmploymentUsers.push(...userWeekData);
    });
    return emptyWeekDataForMonthEmploymentUsers;
}

export default createEmptyWeekDataForMonthEmploymentUsers;