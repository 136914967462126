export const sortingFunction = (data, activeSorting, ascend, usersList) => {
  const userLookup = usersList.reduce((acc, user) => {
    acc[user.value] = user.label;
    return acc;
}, {});

  if (activeSorting === "date") {
    data.sort((a, b) => {
      if (ascend)
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      else return new Date(b.date).getTime() - new Date(a.date).getTime();
    });
  } else if (activeSorting === "user") {
    data.sort((a, b) => {
        // Get `user_name` directly from the lookup object
        const nameA = userLookup[a.user_id] ? userLookup[a.user_id].trim().toLowerCase() : "";
        const nameB = userLookup[b.user_id] ? userLookup[b.user_id].trim().toLowerCase() : "";
        // Compare based on `ascend` order
        const comparisonResult = ascend
            ? nameA.localeCompare(nameB)
            : nameB.localeCompare(nameA);

        return comparisonResult;
    });
}
else if (activeSorting === "certified") {
    if (ascend) {
      data.sort((a, b) => {
        return a.certified - b.certified;
      });
    } else {
      data.sort((a, b) => {
        return b.certified - a.certified;
      });
    }
  } else if (activeSorting === "export") {
    if (ascend) {
      data.sort((a, b) => {
        return a.export - b.export;
      });
    } else {
      data.sort((a, b) => {
        return b.export - a.export;
      });
    }
  } else if (activeSorting === "comp_30") {
    if (ascend) {
      data.sort((a, b) => {
        return a.comp_30 - b.comp_30;
      });
    } else {
      data.sort((a, b) => {
        return b.comp_30 - a.comp_30;
      });
    }
  } else if (activeSorting === "comp_50") {
    if (ascend) {
      data.sort((a, b) => {
        return a.comp_50 - b.comp_50;
      });
    } else {
      data.sort((a, b) => {
        return b.comp_50 - a.comp_50;
      });
    }
  } else if (activeSorting === "comp_70") {
    if (ascend) {
      data.sort((a, b) => {
        return a.comp_70 - b.comp_70;
      });
    } else {
      data.sort((a, b) => {
        return b.comp_70 - a.comp_70;
      });
    }
  } else if (activeSorting === "comp_100") {
    if (ascend) {
      data.sort((a, b) => {
        return a.comp_100 - b.comp_100;
      });
    } else {
      data.sort((a, b) => {
        return b.comp_100 - a.comp_100;
      });
    }
  } else if (activeSorting === "travel") {
    if (ascend) {
      data.sort(function (a, b) {
        if (a.has_travel_expences < b.has_travel_expences) {
          return -1;
        }
        if (a.has_travel_expences > b.has_travel_expences) {
          return 1;
        }
        return 0;
      });
    } else {
      data.sort(function (a, b) {
        if (a.has_travel_expences < b.has_travel_expences) {
          return 1;
        }
        if (a.has_travel_expences > b.has_travel_expences) {
          return -1;
        }
        return 0;
      });
    }
  } else if (activeSorting === "allowance") {
    if (ascend) {
      data.sort(function (a, b) {
        if (a.has_allowance < b.has_allowance) {
          return -1;
        }
        if (a.has_allowance > b.has_allowance) {
          return 1;
        }
        return 0;
      });
    } else {
      data.sort(function (a, b) {
        if (a.has_allowance < b.has_allowance) {
          return 1;
        }
        if (a.has_allowance > b.has_allowance) {
          return -1;
        }
        return 0;
      });
    }
  } else if (activeSorting === "outcall") {
    if (ascend) {
      data.sort(function (a, b) {
        if (a.out_call < b.out_call) {
          return -1;
        }
        if (a.out_call > b.out_call) {
          return 1;
        }
        return 0;
      });
    } else {
      data.sort(function (a, b) {
        if (a.out_call < b.out_call) {
          return 1;
        }
        if (a.out_call > b.out_call) {
          return -1;
        }
        return 0;
      });
    }
  } else if (activeSorting === "work_hours") {
    if (ascend) {
      data.sort((a, b) => {
        return a.work_hours - b.work_hours;
      });
    } else {
      data.sort((a, b) => {
        return b.work_hours - a.work_hours;
      });
    }
  } else if (activeSorting === "start") {
    if (ascend) {
      data.sort((a, b) => {
        return a.start_time < b.start_time
          ? -1
          : a.start_time > b.start_time
          ? 1
          : 0;
      });
    } else {
      data.sort((a, b) => {
        return a.start_time < b.start_time
          ? 1
          : a.start_time > b.start_time
          ? -1
          : 0;
      });
    }
  } else if (activeSorting === "stop") {
    if (ascend) {
      data.sort((a, b) => {
        return a.stop_time < b.stop_time
          ? -1
          : a.stop_time > b.stop_time
          ? 1
          : 0;
      });
    } else {
      data.sort((a, b) => {
        return a.stop_time < b.stop_time
          ? 1
          : a.stop_time > b.stop_time
          ? -1
          : 0;
      });
    }
  } else if (activeSorting === "export") {
    if (ascend) {
      data.sort((a, b) => {
        return a.export < b.export ? -1 : a.export > b.export ? 1 : 0;
      });
    } else {
      data.sort((a, b) => {
        return a.export < b.export ? 1 : a.export > b.export ? -1 : 0;
      });
    }
  } else if (activeSorting === "note") {
    if (ascend) {
      data.sort((a, b) => {
        if (a.note === null || a.note === undefined) return 1;
        if (b.note === null || b.note === undefined) return -1;
        return a.note < b.note ? -1 : a.note > b.note ? 1 : 0;
      });
    } else {
      data.sort((a, b) => {
        if (a.note === null || a.note === undefined) return -1;
        if (b.note === null || b.note === undefined) return 1;
        return a.note < b.note ? 1 : a.note > b.note ? -1 : 0;
      });
    }
  } else if (activeSorting === "updated") {
    if (ascend) {
      data.sort((a, b) => {
        return a.last_updated < b.last_updated
          ? -1
          : a.last_updated > b.last_updated
          ? 1
          : 0;
      });
    } else {
      data.sort((a, b) => {
        return a.last_updated < b.last_updated
          ? 1
          : a.last_updated > b.last_updated
          ? -1
          : 0;
      });
    }
  } else if (activeSorting === "type") {
    if (ascend) {
      data.sort((a, b) => {
        return a.absence_type < b.absence_type
          ? -1
          : a.absence_type > b.absence_type
          ? 1
          : 0;
      });
    } else {
      data.sort((a, b) => {
        return a.absence_type < b.absence_type
          ? 1
          : a.absence_type > b.absence_type
          ? -1
          : 0;
      });
    }
  }
  else if (activeSorting === "location") {
    data.sort((a, b) => {
      // Convert city to a string if it's not, or use empty string if it's null/undefined
      let cityA = typeof a.city === 'string' ? a.city.toLowerCase() : '';
      let cityB = typeof b.city === 'string' ? b.city.toLowerCase() : '';
  
  
      if (ascend) {
        return cityA.localeCompare(cityB); // Locale-aware comparison
      } else {
        return cityB.localeCompare(cityA); // Reverse order for descending
      }
    });
  }
  
  
  
  return data;
};
