import "../styles/SideNavBar.css";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockRotateLeft, faMugHot, faCalendar, faPrint } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

function SideNavBar() {
  return (
    <>
        <div className="sidebar">
            <div className="icon-container">
                <NavLink
                    to="/timereport"
                    style={({isActive}) => ({
                        maxWidth: "65px",
                        maxHeight: "65px",
                        minHeight: "65px",
                        minWidth: "65px",
                        color: isActive ? "black" : "#ADB6C7",
                        background: isActive ? "#FEDC46" : "white",
                    })}
                >
                    <FontAwesomeIcon
                        className="clockIcon"
                        icon={faClockRotateLeft}
                        size={"lg"}
                    />
                </NavLink>
            </div>
            <div className="icon-container">
                <NavLink
                    to="/absence"
                    style={({isActive}) => ({
                        maxWidth: "65px",
                        maxHeight: "65px",
                        minHeight: "65px",
                        minWidth: "65px",
                        color: isActive ? "black" : "#ADB6C7",
                        background: isActive ? "#FEDC46" : "white",
                        // create bottom border if not active
                        borderBottom: isActive
                            ? "none"
                            : "1.5px solid rgba(173,182,199,.3)",
                    })}
                >
                    <FontAwesomeIcon className="icon" icon={faMugHot} size={"lg"}/>
                </NavLink>
            </div>
            <div className="icon-container">
                <NavLink
                    to="/calendar"
                    style={({isActive}) => ({
                        maxWidth: "65px",
                        maxHeight: "65px",
                        minHeight: "65px",
                        minWidth: "65px",
                        color: isActive ? "black" : "#ADB6C7",
                        background: isActive ? "#FEDC46" : "white",
                        // create bottom border if not active
                        borderBottom: isActive
                            ? "none"
                            : "1.5px solid rgba(173,182,199,.3)",
                    })}
                >
                    <FontAwesomeIcon className="icon" icon={faCalendar} size={"lg"}/>
                </NavLink>
            </div>
            <div className="icon-container">
                <NavLink
                    to="/calendarprintable"
                    style={({isActive}) => ({
                        maxWidth: "65px",
                        maxHeight: "65px",
                        minHeight: "65px",
                        minWidth: "65px",
                        color: isActive ? "black" : "#ADB6C7",
                        background: isActive ? "#FEDC46" : "white",
                        // create bottom border if not active
                        borderBottom: isActive
                            ? "none"
                            : "1.5px solid rgba(173,182,199,.3)",
                    })}
                >
                    <FontAwesomeIcon className="icon" icon={faPrint} size={"lg"}/>
                </NavLink>
            </div>
        </div>
    </>
  );
}

export default SideNavBar;
