 import React from 'react';
 import { findCostCenter } from "./payTypeIdIndex";
 /** 
  * This function is used to calculate the qualifying period for the employee. If an employee gets sick during the day,
  * the employee is going to report the hours that the employee has worked before the employee got sick, and also the sick hours.
  * 
  * However, the day needs to be recalculated (after conciderations with JIAB) in the background so that the full day is reported as sick (8h),
  * and the hours that the employee worked before the employee got sick is reported as "compensation hours", löneart 850.
  * 
  * Using this strategy, the employee will not loose any worked hours, and no work would have been for free.
  */

 const calculateQualifyingPeriod = (week, matchingAbsence, restructureData) => {
    // Iterate over each entry in the week's entries array
    if (week.employment_type == "hour" && matchingAbsence && week) {
        week.entries.forEach(entry => {
            const date = entry.date;
            // Find entries in matchingAbsence where the dates match
            matchingAbsence.entries.forEach(absence => {
                if (absence.date.substring(0,10) === date && absence.absence_type === "Sjukfrånvaro") {
                    // Modify the original absence entry by zeroing out work hours
                    const work_hours = entry.work_hours;
                    week.total_work_hours -= entry.work_hours;
                    absence.absence_hours = 8; 
                    entry.work_hours = 0;
                    entry.actual_work_hours = 0;
                    matchingAbsence.total_absence_hours += work_hours;

                    // Create a new entry for the compensation hours
                    const payTypeId = 850; // Eller 850 ifall komp, vi sätter lön nu
                    restructureData.push({
                        periodStartDate: absence.date,
                        periodEndDate: absence.date,
                        quantity: work_hours,
                        employmentId: week.employment_number,
                        payTypeCode: "L",
                        payTypeId: payTypeId,
                        costCenter: findCostCenter(payTypeId, week.employment_type),
                    });
                }
            });
        });
    }
};

export default calculateQualifyingPeriod;
