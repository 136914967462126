import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import './styles/Calendar.css';
import CustomEvent from "./CustomEvent";
import CalendarToolbarPrintable from "./CalendarToolbarPrintable";

const localizer = momentLocalizer(moment);

const MonthCalendarPrintable = ({events = [], onNavigate, selectedDate }) => {

    const eventPropGetter = (event) => {
        const eventType = event.type || '';
        return { className: `custom-event ${eventType}` };
    };


    return (
        <div >
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 620 }}
                eventPropGetter={eventPropGetter}
                date={selectedDate}
                components={{
                    toolbar: CalendarToolbarPrintable,
                    event: CustomEvent  // Use the custom event component
                }}
                onNavigate={onNavigate} // Pass the onNavigate handler
            />
        </div>
    );
};

export default MonthCalendarPrintable;
