import React from "react";
import DatePicker from "react-datepicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";



const DateFilter = ({startDate, setStartDate, endDate, setEndDate}) => {

    const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
        <button className="datePickerCustom" onClick={onClick} ref={ref}>
            {value}
            <FontAwesomeIcon icon={faChevronDown} size="xs" className="dateChevron" />
        </button>
    ));

    return (
        <>
            <div className="dateFilter">
                <p className="subHeaderText">FRÅN DATUM</p>
                <div className="filterContainer">
                    <DatePicker
                        id="datePickerFrom"
                        selected={startDate}
                        onChange={(date) => {setStartDate(date); setEndDate(date)}}
                        locale="sv"
                        dateFormat="P"
                        calendarStartDay={1}
                        customInput={<CustomDateInput/>}
                    />
                </div>
            </div>
            <div className="dateFilter">
                <p className="subHeaderText">TILL DATUM</p>
                <div className="filterContainer">
                    <DatePicker
                        id="datePickerTo"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        locale="sv"
                        dateFormat="P"
                        calendarStartDay={1}
                        customInput={<CustomDateInput/>}
                    />
                </div>
            </div>
        </>
    )
}

export default DateFilter;