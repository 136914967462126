import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { fetchAbsenceReports } from "../utils/handleReportsUtils/fetchAbsenceReports";
import { useExportXml } from "../utils/useExportXml";
import { getAllUserNames } from "../utils/renderUtils/getAllUserNames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import EditableAbsenceData from "../components/EditableAbsenceData";
import StatusSymbol from "../components/StatusSymbol";
import DeleteComponent from "../components/DeleteComponent";
import TotalTime from "../components/TotalTime";
import StartTimeInput from "../components/StartTimeInput";
import StopTimeInput from "../components/StopTimeInput";
import NewReportModal from "../components/NewReportModal";
import ExportToggle from "../components/ExportToggle";
import ExportButton from "../components/ExportButton";
import Select from "react-select";
import { sortingFunction } from "../utils/renderUtils/sortActiveData";
import "../styles/AbsenceReports.css";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import sv from "date-fns/locale/sv";
import CertifiedCheckbox from "../components/CertifiedCheckbox";
import CertifyButton from "../components/CertifyButton";
import DropDownInput from "../components/DropDownInput";
import GetAbsenceTypes from "../utils/renderUtils/getAbsenceTypes";
// ** Same structure as TimeReports.js check that file for comments ** //
function AbsenceReports() {
  const currentDate = new Date();
  const [startDate, setStartDate] = useState(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0));
  const [isLoading, setIsLoading] = useState(true);
  const [workItems, setWorkItems] = useState([]);
  const [editFields, setEditFields] = useState(true);
  const [updateRender, setUpdateRender] = useState(false);
  const [users, setUsers] = useState([]);
  const [isPressed, setIsPressed] = useState(false);
  const [ascend, setAscend] = useState(false);
  const [activeData, setActiveData] = useState([]);
  const [requestDataArray, setRequestDataArray] = useState(["", "", "", ""]);
  const [selectedUser, setSelectedUser] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [numberOfExports, setNumberOfExports] = useState(0);
  const [sumatedWorkHours, setSumatedWorkHours] = useState([]);
  const [totalNumberOfExports, setTotalNumberOfExports] = useState(0);
  const [activeSorting, setActiveSorting] = useState("");
  const [currentPageData, setCurrentPageData] = useState([]);
  const [checkedState, setCheckedState] = useState({});
  const [certifyArray, setCertifyArray] = useState([]);
  const [initialDate, setInitialDate] = useState(new Date());
  const [monthEndDate, setMonthEndDate] = useState(new Date());

  //*** PAGINATION AREA START ***/
  const [absenceReports, setAbsenceReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [displayedPages, setDisplayedPages] = useState([]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const { exportXml } = useExportXml();

  const collectExportArray = (data) => {
    let exportz = [...exportData];

    exportz.push(data);
    setNumberOfExports(exportz.length);
    setExportData(exportz);
  };

  const removeItemExportArray = (data) => {
    setExportData(exportData.filter((item) => item.id !== data.id));
    setNumberOfExports(exportData.length - 1);
  };

  let sumatedWorkHoursData = [0, 0, 0, 0, 0, 0];
  let totalMinutes = 0;

  const sumateData = (absence_hours) => {
    if (!absence_hours) {
      absence_hours = "00:00";
    }
    const [hours, minutes] = absence_hours.split(":").map(Number);
    const absenceTimeInMinutes = hours * 60 + minutes;
    totalMinutes += absenceTimeInMinutes;

    let h = Math.floor(totalMinutes / 60);
    let m = totalMinutes % 60;

    const formattedTime = `${h}:${m < 10 ? "0" + m : m}`;
    sumatedWorkHoursData[5] = formattedTime;
    setSumatedWorkHours(sumatedWorkHoursData);
  };

  registerLocale("sv", sv);
  function handleRender() {
    setTimeout(() => {
      setUpdateRender(!updateRender);
    }, 100);
  }
  const handleIsPressed = () => {
    setIsPressed(!isPressed);
  };
  const enableEditFields = () => {
    setEditFields(!editFields);
    handleRender();
  };

  const toggleModal = () => {
    setIsVisible(!isVisible);
  };

  const userFilter = async (requestDataArray) => {
    var newStartDate = startDate.setHours(2, 0, 0); //Timezone fix
    newStartDate = startDate.toISOString().slice(0, 10);
    endDate.setHours(23, 59, 59);
    let data = await fetchAbsenceReports(
      newStartDate,
      endDate,
      requestDataArray,
      currentPage,
      pageSize
    );
    const workItems = await AbsenceReportComponent(data);
    setWorkItems(workItems);
    const filteredData = await data.filter(
      (item) => parseInt(item.certified) === 1
    );
    setNumberOfExports(filteredData.length);
    setExportData(filteredData);
  };

  const updateTable = (selectedUser) => {
    setRequestDataArray([selectedUser, "", "", ""]);
    setIsPressed(false);
    setSelectedUser(selectedUser);
    userFilter(requestDataArray);
    collectExportArray(exportData);
    handleRender();
  };
  const absenceTypes = [
    { value: "", label: "Inget filter" },
    { value: "ATF", label: "ATF" },
    { value: "Sjukfrånvaro", label: "Sjukfrånvaro" },
    { value: "Bet. Semester", label: "Bet. Semester" },
    { value: "OBet. Semester", label: "OBet. Semester" },
    { value: "Tjänstledighet", label: "Tjänstledighet" },
    { value: "Permission", label: "Permission" },
    { value: "Föräldraledighet", label: "Föräldraledighet" },
    { value: "VAB", label: "VAB" },
    { value: "komp", label: "Uttag av komp.tid" },
    { value: "Otillåten frånvaro", label: "Otillåten frånvaro" },
  ];

  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <button className="datePickerCustom" onClick={onClick} ref={ref}>
      {value}
      <FontAwesomeIcon icon={faChevronDown} size="xs" className="dateChevron" />
    </button>
  ));

  const AbsenceReportComponent = async (data, usersList) => {
    if (usersList === undefined) {
      usersList = users;
    }
    data.map((item) => {
      // format data
      item.date = item.date.slice(0, 10);
      item.start_time = item.start_time.slice(0, 5);
      item.stop_time = item.stop_time.slice(0, 5);
      usersList.map((user) => {
        item.user_id = parseInt(item.user_id);
        user.value = parseInt(user.value);
        if (item.user_id == user.value) {
          // add the username to the item
          item.user_name = user.label;
        } else {
          // do nothing
        }
      });
    });

    const renderObjects = [];
    let index = 0;

    await data.map((element) => {
      if (index % 2 === 0) {
        sumateData(element.absence_hours);
        renderObjects.push(
          <tbody key={element.date + element.id} className="evenItemBody">
            <tr className="evenItemRow">
              <td className="dateTable">
                <CertifiedCheckbox
                  element={element}
                  setCertifyArray={setCertifyArray}
                  checkedState={checkedState}
                  setCheckedState={setCheckedState}
                  handleRender={handleRender}
                />
                <EditableAbsenceData
                  editFields={editFields}
                  value={element.date}
                  type={"date"}
                  id={element.id}
                  handleRender={handleRender}
                />
              </td>
              <td>
                <StatusSymbol
                  bool={element.certified}
                  id={element.id}
                  handleRender={handleRender}
                  path={"absence"}
                  currentPageData={currentPageData}
                  activeData={activeData}
                />
              </td>
              <td className="userNameField">
                {isPressed ? (
                  <div className="deleteContainer">
                    <DeleteComponent
                      id={element.id}
                      handleRender={handleRender}
                      path={"absence"}
                    />{" "}
                    {element.user_name}
                  </div>
                ) : (
                  element.user_name
                )}
              </td>
              <td>
                <DropDownInput
                  editFields={editFields}
                  isAbsenceReport={true}
                  value={element.absence_type}
                  type={"absence_type"}
                  id={element.id}
                  handleRender={handleRender}
                  getOptionsFunction={GetAbsenceTypes}
                />
              </td>
              <td>
                <StartTimeInput
                  editFields={editFields}
                  value={element.start_time}
                  stopTime={element.stop_time}
                  id={element.id}
                  breakTime={element.break_time}
                  handleRender={handleRender}
                  path={"absence"}
                />
              </td>
              <td>
                <StopTimeInput
                  editFields={editFields}
                  value={element.stop_time}
                  startTime={element.start_time}
                  id={element.id}
                  breakTime={element.break_time}
                  handleRender={handleRender}
                  path={"absence"}
                />
              </td>
              <td>
                <ExportToggle
                  element={element}
                  collectExportArray={collectExportArray}
                  removeItemExportArray={removeItemExportArray}
                  handleRender={handleRender}
                  type={"absence"}
                />
              </td>
              <td>
                <TotalTime
                  value={element.start_time}
                  value2={element.stop_time}
                  breakTime={"0"}
                  isExport={element.export}
                  id={element.id}
                />
              </td>
              <td>
                <EditableAbsenceData
                  editFields={editFields}
                  setEditFields={setEditFields}
                  value={element.note}
                  type={"note"}
                  id={element.id}
                  handleRender={handleRender}
                />
              </td>

              <td>
                <EditableAbsenceData
                  editFields={editFields}
                  setEditFields={setEditFields}
                  value={element.last_updated}
                  type={"last_updated"}
                  id={element.id}
                  handleRender={handleRender}
                />
              </td>
            </tr>
          </tbody>
        );
      } else {
        sumateData(element.absence_hours);

        renderObjects.push(
          <tbody key={element.date + element.id} className="oddItemBody">
            <tr className="oddItemRow">
              <td className="dateTable">
                <CertifiedCheckbox
                  element={element}
                  setCertifyArray={setCertifyArray}
                  checkedState={checkedState}
                  setCheckedState={setCheckedState}
                  handleRender={handleRender}
                />
                <EditableAbsenceData
                  editFields={editFields}
                  setEditFields={setEditFields}
                  value={element.date}
                  type={"date"}
                  id={element.id}
                  handleRender={handleRender}
                />
              </td>
              <td>
                <StatusSymbol
                  bool={element.certified}
                  id={element.id}
                  handleRender={handleRender}
                  path={"absence"}
                  currentPageData={currentPageData}
                  activeData={activeData}
                />
              </td>
              <td className="userNameField">
                {isPressed ? (
                  <div className="deleteContainer">
                    <DeleteComponent
                      id={element.id}
                      handleRender={handleRender}
                      path={"absence"}
                    />{" "}
                    {element.user_name}
                  </div>
                ) : (
                  element.user_name
                )}
              </td>
              <td>
                <DropDownInput
                  editFields={editFields}
                  isAbsenceReport={true}
                  value={element.absence_type}
                  type={"absence_type"}
                  id={element.id}
                  handleRender={handleRender}
                  getOptionsFunction={GetAbsenceTypes}
                />
              </td>
              <td>
                <StartTimeInput
                  editFields={editFields}
                  value={element.start_time}
                  stopTime={element.stop_time}
                  id={element.id}
                  path={"absence"}
                  handleRender={handleRender}
                />
              </td>
              <td>
                <StopTimeInput
                  editFields={editFields}
                  value={element.stop_time}
                  startTime={element.start_time}
                  id={element.id}
                  path={"absence"}
                  handleRender={handleRender}
                />
              </td>
              <td>
                <ExportToggle
                  element={element}
                  collectExportArray={collectExportArray}
                  removeItemExportArray={removeItemExportArray}
                  handleRender={handleRender}
                  type={"absence"}
                />
              </td>
              <td>
                <TotalTime
                  value={element.start_time}
                  value2={element.stop_time}
                  breakTime={"0"}
                  isExport={element.export}
                  id={element.id}
                />
              </td>
              <td>
                <EditableAbsenceData
                  editFields={editFields}
                  setEditFields={setEditFields}
                  value={element.note}
                  type={"note"}
                  id={element.id}
                  handleRender={handleRender}
                />
              </td>

              <td>
                <EditableAbsenceData
                  editFields={editFields}
                  setEditFields={setEditFields}
                  value={element.last_updated}
                  type={"last_updated"}
                  id={element.id}
                  handleRender={handleRender}
                />
              </td>
            </tr>
          </tbody>
        );
      }
      index++;
    });
    return renderObjects;
  };

  useEffect(() => {
    const fetchData = async () => {
      const newEndDate = new Date(startDate);
      newEndDate.setDate(newEndDate.getDate());
      // make the start date a week before todays date
      const newStartDate = new Date();
      newStartDate.setDate(newStartDate.getDate());
      newStartDate.setHours(2,0,0);
      newEndDate.setHours(23,59,59);
      let data = await fetchAbsenceReports(
        newStartDate,
        newEndDate,
        requestDataArray
      );
      data.forEach((item) => {
        const [startHour, startMinute] = item.start_time.split(":").map(Number);
        const [endHour, endMinute] = item.stop_time.split(":").map(Number);
        const startTimeInMinutes = startHour * 60 + startMinute;
        const endTimeInMinutes = endHour * 60 + endMinute;
        const totalTimeInMinutes = endTimeInMinutes - startTimeInMinutes;
        const totalHours = totalTimeInMinutes / 60;
        item.absence_hours = totalHours;
      });
      const filteredData = await data.filter(
        (item) => parseInt(item.certified) === 1
      );

      setExportData(await filteredData);
      setIsLoading(false);
      const usersData = await getAllUserNames();
      const userArray = [{ value: 0, label: "All" }];
      setUsers(await usersData);
      setActiveData(await data);
      await usersData.map((user) => {
        // create a object with the user id as key and the full name as value
        const userObject = {
          value: user.ID,
          label: user.full_name,
          employee_number: user.employee_number,
          employee_employment: user.employee_employment,
        };
        // add the object to the users array
        userArray.push(userObject);
      });
      setUsers(userArray);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const newStartDate = new Date(startDate);
      let data = await fetchAbsenceReports(
        newStartDate.toISOString().slice(0, 10),
        endDate,
        requestDataArray
      );
      data.forEach((item) => {
        const [startHour, startMinute] = item.start_time.split(":").map(Number);
        const [endHour, endMinute] = item.stop_time.split(":").map(Number);
        const startTimeInMinutes = startHour * 60 + startMinute;
        const endTimeInMinutes = endHour * 60 + endMinute;
        const totalTimeInMinutes = endTimeInMinutes - startTimeInMinutes - 60;
        const totalHours = totalTimeInMinutes / 60;
        const formattedHours = Math.floor(totalHours);
        const formattedMinutes = Math.round((totalHours - formattedHours) * 60);
        item.absence_hours = `${formattedHours}:${(
          "0" + formattedMinutes
        ).slice(-2)}`;
      });

      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const slicedData = data.slice(startIndex, endIndex);
      const usersData = await getAllUserNames();
      const userArray = [{ value: 0, label: "All" }];
      await usersData.map((user) => {
        // create a object with the user id as key and the full name as value
        const userObject = {
          value: user.ID,
          label: user.full_name,
          employee_number: user.employee_number,
          employee_employment: user.employee_employment,
        };
        userArray.push(userObject);
      });
      setUsers(userArray);
      const workItems = await AbsenceReportComponent(slicedData, userArray);
      setWorkItems(workItems);
      setTotalPages(Math.ceil(data.length / pageSize));
      if (totalPages <= 10) {
        setDisplayedPages(Array.from({ length: totalPages }, (_, i) => i + 1));
      } else {
        setDisplayedPages([1, 2, 3, 4, 5, "...", totalPages - 1, totalPages]);
      }
      setAbsenceReports(await data);
      setTotalNumberOfExports(data.length);
      const filteredData = await data.filter(
        (item) => parseInt(item.certified) === 1
      );
      setNumberOfExports(filteredData.length);
      setExportData(filteredData);
      setActiveData(await data);
      setCurrentPageData(slicedData);
    };
    fetchData();
  }, [startDate, endDate]);

  useEffect(() => {
    const fetchData = async () => {
      const newStartDate = new Date(startDate);
      endDate.setHours(23, 59, 59);
      let data = await fetchAbsenceReports(
        newStartDate.toISOString().slice(0, 10),
        endDate,
        requestDataArray
      );
      data.forEach((item) => {
        const [startHour, startMinute] = item.start_time.split(":").map(Number);
        const [endHour, endMinute] = item.stop_time.split(":").map(Number);
        const startTimeInMinutes = startHour * 60 + startMinute;
        const endTimeInMinutes = endHour * 60 + endMinute;
        const totalTimeInMinutes = endTimeInMinutes - startTimeInMinutes - 60;
        const totalHours = totalTimeInMinutes / 60;
        const formattedHours = Math.floor(totalHours);
        const formattedMinutes = Math.round((totalHours - formattedHours) * 60);
        item.absence_hours = `${formattedHours}:${(
          "0" + formattedMinutes
        ).slice(-2)}`;
      });

      const indexMap = activeData.reduce((map, item, index) => {
        map[item.id] = index;
        return map;
      }, {});

      await data.sort((a, b) => indexMap[a.id] - indexMap[b.id]);

      data = sortingFunction(data, activeSorting, ascend, users);

      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const slicedData = data.slice(startIndex, endIndex);
      const usersData = await getAllUserNames();
      const userArray = [{ value: 0, label: "All" }];
      await usersData.map((user) => {
        // create a object with the user id as key and the full name as value
        const userObject = {
          value: user.ID,
          label: user.full_name,
          employee_number: user.employee_number,
          employee_employment: user.employee_employment,
        };
        userArray.push(userObject);
      });
      setUsers(userArray);
      const workItems = await AbsenceReportComponent(slicedData, userArray);
      setWorkItems(workItems);
      setTotalPages(Math.ceil(data.length / pageSize));
      if (totalPages <= 10) {
        setDisplayedPages(Array.from({ length: totalPages }, (_, i) => i + 1));
      } else {
        setDisplayedPages([1, 2, 3, 4, 5, "...", totalPages - 1, totalPages]);
      }
      setAbsenceReports(data);
      setTotalNumberOfExports(data.length);
      // remove all items from data that has export === "0"
      const filteredData = await data.filter(
        (item) => parseInt(item.certified) === 1
      );
      setNumberOfExports(filteredData.length);
      setExportData(filteredData);
      setCurrentPageData(slicedData);
    };
    fetchData();
  }, [requestDataArray, updateRender, currentPage, pageSize, activeData]);

  useEffect(() => {
    const fetchData = async () => {
      setTotalPages(Math.ceil(absenceReports.length / pageSize));
      if (totalPages <= 10) {
        setDisplayedPages(Array.from({ length: totalPages }, (_, i) => i + 1));
      } else {
        setDisplayedPages([1, 2, 3, 4, 5, "...", totalPages - 1, totalPages]);
      }
    };
    fetchData();
  }, [pageSize, currentPage, totalPages]);

  


  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#F2F5FA",
      border: "none",
      borderRadius: "10px",
      height: "51px",
      width: "259px",
      margin: "5px",
    }),
  };

  return (
    <div className="mainContainer">
      <div className="HeaderContainer">
        <div className="Header">
          <header>FRÅNVARO</header>
        </div>
        <div className="Tab"></div>
      </div>
      <div className="subHeaderContainer">
        <div className="dateFilter">
          <p className="subHeaderText">FRÅN DATUM</p>
          <div className="filterContainer">
            <DatePicker
              selected={startDate}
              onChange={(date) => {setStartDate(date)}}
              locale="sv"
              dateFormat="P"
              calendarStartDay={1}
              customInput={<ExampleCustomInput />}
            />
          </div>
        </div>
        <div className="dateFilter">
          <p className="subHeaderText">TILL DATUM</p>
          <div className="filterContainer">
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              locale="sv"
              dateFormat="P"
              calendarStartDay={1}
              customInput={<ExampleCustomInput />}
            />
          </div>
        </div>
        <div className="userFilter">
          <p className="subHeaderText">FRÅNVAROTYP</p>
          <div className="filterContainer">
            <Select
              placeholder="Frånvarotyp"
              options={absenceTypes}
              styles={colourStyles}
              onChange={(e) => {
                const requestDataArrayCopy = [...requestDataArray];
                requestDataArrayCopy[2] = e.value;
                setRequestDataArray(requestDataArrayCopy);
                userFilter(requestDataArrayCopy);
              }}
            />
          </div>
        </div>
        <div className="userFilter">
          <p className="subHeaderText">ANVÄNDARE</p>
          <div className="filterContainer">
            <Select
              placeholder="Användare"
              options={users}
              styles={colourStyles}
              onChange={(e) => {
                const requestDataArrayCopy = [...requestDataArray];
                requestDataArrayCopy[0] = e.value;
                setRequestDataArray(requestDataArrayCopy);
                userFilter(requestDataArrayCopy);
                setSelectedUser(e.value);
              }}
            />
          </div>
        </div>
        <div className="buttonContainer">
          <ExportButton
            exportData={exportData}
            usersList={users}
            entireWeekData={"entireWeekData"}
            requestDataArray={requestDataArray}
            path={"absence"}
          />
          <button
            className="button"
            onClick={() =>
              setRequestDataArray(["", "", "", ""]) +
              setIsPressed(false) +
              setSelectedUser(0) +
              userFilter()
            }
          >
            <FontAwesomeIcon icon={faRotate} size="lg" color="white" />
          </button>
          <button
            className="button"
            onClick={() => enableEditFields() + handleIsPressed()}
            style={{ backgroundColor: isPressed ? "#15305D80" : "#B1B9C8" }}
          >
            <FontAwesomeIcon icon={faPenToSquare} size="lg" color="white" />
          </button>
          {isPressed ? (
            <button
              className="button"
              onClick={() =>
                enableEditFields() + handleIsPressed() + setIsVisible(true)
              }
            >
              <FontAwesomeIcon icon={faPlus} size="lg" color="white" />
            </button>
          ) : null}
          <NewReportModal
            isVisible={isVisible}
            toggleModal={toggleModal}
            selectedUser={selectedUser}
            users={users}
            type={"absence"}
            updateTable={updateTable}
          />
        </div>
      </div>
      <table className="tableContainer">
      
        <thead className="tableHead">
          <tr className="tableRow">
            <th
              onClick={() =>
                setActiveSorting("date") + setAscend(!ascend) + handleRender()
              }
            >
              DATUM
            </th>
            <th
              onClick={() =>
                setActiveSorting("certified") +
                setAscend(!ascend) +
                handleRender()
              }
            >
              STATUS
            </th>
            <th
              className="userNameField"
              onClick={() =>
                setActiveSorting("user") + setAscend(!ascend) + handleRender()
              }
            >
              ANVÄNDARE
            </th>
            <th
              onClick={() =>
                setActiveSorting("type") + setAscend(!ascend) + handleRender()
              }
            >
              TYP
            </th>
            <th
              onClick={() =>
                setActiveSorting("start") + setAscend(!ascend) + handleRender()
              }
            >
              STARTTID
            </th>
            <th
              onClick={() =>
                setActiveSorting("stop") + setAscend(!ascend) + handleRender()
              }
            >
              SLUTTID
            </th>
            <th
              onClick={() =>
                setActiveSorting("export") + setAscend(!ascend) + handleRender()
              }
            >
              EXPORT
            </th>
            {/* <th>FRÅNVARO D</th> */}
            <th>FRÅNVARO H</th>
            <th
              onClick={() =>
                setActiveSorting("note") + setAscend(!ascend) + handleRender()
              }
            >
              NOTERING
            </th>
            <th
              onClick={() =>
                setActiveSorting("updated") +
                setAscend(!ascend) +
                handleRender()
              }
            >
              UPPDATERAD
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <td>
              <CertifyButton
                path="absence"
                activeData={activeData}
                checkedState={checkedState}
                setCheckedState={setCheckedState}
                currentPageData={currentPageData}
                handleRender={handleRender}
                certifyArray={certifyArray}
                setCertifyArray={setCertifyArray}
              >
                Certifiera
              </CertifyButton>
            </td>
            <td colSpan="1"></td>
            <td colSpan="1"></td>
            <td colSpan="1"></td>
            <td colSpan="1"></td>
            <td colSpan="1"></td>
            <td className="col-footer" colSpan="1"></td>
            <td className="col-footer" colSpan="1"></td>
            <td colSpan="1"></td>
          </tr>
        </thead>
        {isLoading ? (
          <tbody>
            <tr>
              <td>loading</td>
            </tr>
          </tbody>
        ) : (
          workItems
        )}
        <tfoot>
          <tr>
            <td>
              <CertifyButton
                path="absence"
                activeData={activeData}
                checkedState={checkedState}
                setCheckedState={setCheckedState}
                currentPageData={currentPageData}
                handleRender={handleRender}
                certifyArray={certifyArray}
                setCertifyArray={setCertifyArray}
              >
                Certifiera
              </CertifyButton>
            </td>
            <td colSpan="1"></td>
            <td colSpan="1"></td>
            <td colSpan="1"></td>
            <td colSpan="1"></td>
            <td colSpan="1"></td>
            <td className="col-footer" colSpan="1">
              {numberOfExports + "/" + totalNumberOfExports}
            </td>
            <td className="col-footer" colSpan="1">
              {sumatedWorkHours[5]}
            </td>
            <td colSpan="1"></td>
          </tr>
        </tfoot>
      </table>
      <div className="paginationContainer">
        <div className="paginationNavigation">
          <button
            className="paginationNavigationButton"
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1}
          >
            {"<<"}
          </button>
          <button
            className="paginationNavigationButton"
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            {"<"}
          </button>
          {displayedPages.map((pageNumber, index) => {
            return pageNumber === "..." ? (
              <span key={index}>{pageNumber}</span>
            ) : (
              <button
                key={index}
                className="paginationLink"
                onClick={() => setCurrentPage(pageNumber)}
                // make the active page stand out
                style={{
                  fontWeight: pageNumber === currentPage ? "700" : "600",
                  color: pageNumber === currentPage ? "black" : "#ADB6C7",
                }}
              >
                {pageNumber}
              </button>
            );
          })}
          <button
            className="paginationNavigationButton"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            {">"}
          </button>
          <button
            className="paginationNavigationButton"
            onClick={() => setCurrentPage(totalPages)}
            disabled={currentPage === totalPages}
          >
            {">>"}
          </button>
        </div>
        <div className="paginationTotalLines">
          <p style={{ fontSize: 13, marginRight: 2, fontWeight: 700 }}>
            Antal rader:
          </p>

          <p style={{ color: "black", fontSize: 13, fontWeight: 600 }}>
            {" "}
            {totalNumberOfExports}
          </p>
        </div>
      </div>
    </div>
  );
}

export default AbsenceReports;
