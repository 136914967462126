import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import TimeReports from "../pages/TimeReports";
import AbsenceReports from "../pages/AbsenceReports";
import LoginPage from "../pages/loginPage";
import TopNavBar from "../components/TopNavBar";
import SideNavBar from "../components/SideNavBar";
import CalendarView from "../pages/CalendarView";
import CalendarPrintView from "../pages/CalendarPrintView";
import getCustomerLogo from "../utils/renderUtils/getCustomerLogo";
import getCustomerUrl from "../utils/renderUtils/getCustomerUrl";
import "../styles/App.css";

import { useEffect } from "react";

const CustomRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState("placeholder");
  const [loggedInUserData, setLoggedInUserData] = useState({});
  const [customerUrl, setCustomerUrl] = useState("placeholder");
  const [customerLogo, setCustomerLogo] = useState("placeholder");
  getCustomerUrl(loggedInUserData.username).then((url) => {
    setCustomerUrl(url);
  });
  getCustomerLogo(customerUrl).then((logo) => {
    setCustomerLogo(logo.req.data.customer_logo);
    return logo;
  });

  const handleLoggedInUserData = (data) => {
    setLoggedInUserData(data);
    localStorage.setItem("loggedInUser", data);
  };

  const logoutUser = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("loggedInUser");
    localStorage.removeItem("username");

    setToken("placeholder");
    setIsAuthenticated(false);
    window.location.reload();
  };

  useEffect(() => {
    const storageToken = localStorage.getItem("token");
    if (storageToken !== token) {
      setToken(storageToken);
    }
  }, []); 

  useEffect(() => {
    if (token) {
      setIsAuthenticated(true);
    }
  }, [token]); 

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <Routes>
      <Route
        path="/login"
        element={
          <LoginPage
            handleLoggedInUserData={handleLoggedInUserData}
            setIsAuthenticated={setIsAuthenticated}
          />
        }
      />
      <Route
        path="/timereport"
        element={
          <>
            <TopNavBar
              loggedInUserData={loggedInUserData}
              logoutUser={logoutUser}
              customerLogo={customerLogo}
              title={"Arbetstider"}
            />
            <SideNavBar />
            <div className="container">
              <TimeReports loggedInUserData={loggedInUserData} />
            </div>
          </>
        }
      />
      <Route
        path="/absence"
        element={
          <>
            <TopNavBar
              loggedInUserData={loggedInUserData}
              logoutUser={logoutUser}
              customerLogo={customerLogo}
              title={"Frånvaro"}
            />
            <SideNavBar />
            <div className="container">
              <AbsenceReports loggedInUserData={loggedInUserData} />
            </div>
          </>
        }
      />
      <Route
          path="/calendar"
          element={
            <>
              <TopNavBar
                  loggedInUserData={loggedInUserData}
                  logoutUser={logoutUser}
                  customerLogo={customerLogo}
                  title={"Kalender"}
              />
              <SideNavBar />
              <div className="container">
                <CalendarView loggedInUserData={loggedInUserData} />
              </div>
            </>
          }
      />
      <Route
          path="/calendarprintable"
          element={
            <>
              <TopNavBar
                  loggedInUserData={loggedInUserData}
                  logoutUser={logoutUser}
                  customerLogo={customerLogo}
                  title={"Kalender"}
              />
              <SideNavBar />
              <div className="container">
                <CalendarPrintView loggedInUserData={loggedInUserData} />
              </div>
            </>
          }
      />
    </Routes>
  );
};

export default CustomRoutes;
