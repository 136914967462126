import React, { useState, useEffect} from "react";
import { fetchUserData } from "../utils/handleReportsUtils/fetchUserData";
import { getAllUserNames } from "../utils/renderUtils/getAllUserNames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faPenToSquare,
  faPlus,
  faRotate,
} from "@fortawesome/free-solid-svg-icons";
import ToggleSwitch from "../components/ToggleSwitch";
import DeleteComponent from "../components/DeleteComponent";
import EditableInput from "../components/EditableInput";
import StatusSymbol from "../components/StatusSymbol";
import EditableSymbol from "../components/EditableSymbol";
import TotalTime from "../components/TotalTime";
import StartTimeInput from "../components/StartTimeInput";
import StopTimeInput from "../components/StopTimeInput";
import NewReportModal from "../components/NewReportModal";
import ExportToggle from "../components/ExportToggle";
import ExportButton from "../components/ExportButton";
import CertifyButton from "../components/CertifyButton";
import CertifiedCheckbox from "../components/CertifiedCheckbox";
import DropDownInput from "../components/DropDownInput";
import "../styles/AbsenceReports.css";
import { registerLocale } from "react-datepicker";
import sv from "date-fns/locale/sv";
import { sortingFunction } from "../utils/renderUtils/sortActiveData";
import { fetchEntireWeeksTimeReports } from "../utils/exportUtils/fetchEntireWeek";
import getCities from "../utils/renderUtils/getCities";
import UserSelect from "../components/TimeReports/UserSelect";
import DateFilter from "../components/TimeReports/DateFilter";

function TimeReports() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [workItems, setWorkItems] = useState([]);
  const [editFields, setEditFields] = useState(true);
  const [updateRender, setUpdateRender] = useState(true || false);
  const [users, setUsers] = useState([]);
  const [isPressed, setIsPressed] = useState(false);
  const [selectedUser, setSelectedUser] = useState(0);
  const [requestDataArray, setRequestDataArray] = useState(["", "", "", ""]);
  const [ascend, setAscend] = useState(true);
  const [activeData, setActiveData] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [exportData, setExportData] = useState();
  const [sumatedWorkHours, setSumatedWorkHours] = useState([]);
  const [numberOfExports, setNumberOfExports] = useState(0);
  const [totalNumberOfExports, setTotalNumberOfExports] = useState(0);
  const [timeReports, setTimeReports] = useState([]);
  const [activeSorting, setActiveSorting] = useState("");
  const [entireWeekData, setEntireWeekData] = useState([]);
  const [certifyArray, setCertifyArray] = useState([]);
  const [checkedState, setCheckedState] = useState({});
  const [currentPageData, setCurrentPageData] = useState([]);

  registerLocale("sv", sv);

  //*** SUMATING DATA AND FORMATTING FOR FOOTER AREA START ***/
  const [totalTime, setTotalTime] = useState("0:0");
  function addTime(time) {
    var splitTime = time.split(":");
    totalMinutes += parseInt(splitTime[0]) * 60 + parseInt(splitTime[1]);
    var hours = Math.floor(totalMinutes / 60);
    var minutes = totalMinutes % 60;
    setTotalTime(hours + ":" + minutes);
  }

  let sumatedWorkHoursData = [0, 0, 0, 0, 0]; // Initialize if not already done
  let totalMinutes = 0; // Maintain a running total of minutes

  const sumateData = (workHours, comp_30, comp_50, comp_70, comp_100) => {
    // Update totals with new data
    sumatedWorkHoursData[0] += parseFloat(workHours);
    sumatedWorkHoursData[1] += parseFloat(comp_30);
    sumatedWorkHoursData[2] += parseFloat(comp_50);
    sumatedWorkHoursData[3] += parseFloat(comp_70);
    sumatedWorkHoursData[4] += parseFloat(comp_100);

    // Round the values to two decimal places
    sumatedWorkHoursData = sumatedWorkHoursData.map(
      (data) => Math.round(data * 100) / 100
    );

    // Update the total minutes with new work hours
    totalMinutes += parseFloat(workHours) * 60;

    // Calculate the total hours and minutes
    let hours = Math.floor(totalMinutes / 60);
    let minutes = Math.round(totalMinutes % 60);

    // Format the time string
    let time = `${hours}:`;
    if (minutes < 10) {
      time += `0${minutes}`;
    } else {
      time += minutes;
    }

    // Update the work hours in the array with the formatted time
    sumatedWorkHoursData[0] = time;

    // Assuming you have a function to update state or handle the updated data
    setSumatedWorkHours(sumatedWorkHoursData);
  };

  // *** SUMATING DATA AND FORMATTING FOR FOOTER AREA END ***/

  //*** PAGINATION AREA START ***/
  const [absenceReports, setAbsenceReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [displayedPages, setDisplayedPages] = useState([]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  //*** PAGINATION AREA END ***/

  //*** COLLECTING REPORTS FOR EXPORT AREA ***/

  const collectExportArray = (data) => {
    let exportz = [...exportData];

    // const filteredData = data.filter((item) => item.export === "0");
    exportz.push(data);
    setNumberOfExports(exportz.length);

    setExportData(exportz);
  };

  const removeItemExportArray = (data) => {
    setExportData(exportData.filter((item) => item.id !== data.id));
    setNumberOfExports(exportData.length - 1);
  };

  //*** COLLECTING REPORTS FOR EXPORT AREA END   **/

  //** SELECTED DATE HANDLING AREA  **/

  const updateActiveData = () => {
    const fetchData = async () => {
      const newEndDate = new Date(startDate);
      newEndDate.setDate(newEndDate.getDate());
      // make the start date a week before todays date
      const newStartDate = new Date();
      newStartDate.setDate(newStartDate.getDate());
      // convert newStartDate and newEndDate to strings
      newEndDate.toISOString().slice(0, 10);
      let data = await fetchUserData(
        newStartDate.toISOString().slice(0, 10),
        newEndDate.toISOString().slice(0, 10),
        requestDataArray
      );
      setActiveData(await data);
    };
    fetchData();
  };
/*
  const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
    <button className="datePickerCustom" onClick={onClick} ref={ref}>
      {value}
      <FontAwesomeIcon icon={faChevronDown} size="xs" className="dateChevron" />
    </button>
  ));*/

  // ** SELECTED DATE HANDLING AREA END **/

  // ** UPDATING RENDER AREA START  **/

  function handleRender() {
    setTimeout(() => {
      setUpdateRender(!updateRender);
    }, 100);
  }
  const handleIsPressed = () => {
    setIsPressed(!isPressed);
  };
  const enableEditFields = () => {
    setEditFields(!editFields);
    handleRender();
  };

  const toggleModal = () => {
    setIsVisible(!isVisible);
  };

  const userFilter = async (requestDataArray) => {
    let newStartDate = startDate.toISOString().slice(0, 10);
    let data = await fetchUserData(
      newStartDate,
      endDate,
      requestDataArray,
      currentPage,
      pageSize
    );
    const workItems = await TimeReportsComponent(data);
    setCurrentPageData(await data);
    setWorkItems(workItems);
    const filteredData = await data.filter(
      (item) => parseInt(item.certified) === 1
    );
    setNumberOfExports(filteredData.length);
    setExportData(filteredData);
  };
  //** UPDATING RENDER AREA END  **/

  // ** RENDER AREA START **/
  const TimeReportsComponent = async (data, usersList) => {
    if (usersList === undefined) {
      usersList = users;
    }
    await data.map((item) => {
      // format date and start/stop time
      if (item.date.length > 10) {
        item.date = item.date.slice(0, 10);
      }
      if (item.start_time !== null && item.start_time.length > 5) {
        item.start_time = item.start_time.slice(0, 5);
      }
      if (item.stop_time !== null && item.stop_time.length > 5) {
        item.stop_time = item.stop_time.slice(0, 5);
      }
      item.user_id = parseInt(item.user_id);

      // add the username to the item
      usersList.map((user) => {
        user.value = parseInt(user.value);
        if (item.user_id === user.value) {
          item.user_name = user.label;
        } else {
          // do nothing
        }
      });
    });
    const renderObjects = [];
    let index = 0;
    await data.map((element) => {
      // Rendering the even rows
      if (index % 2 === 0) {
        sumateData(
          element.work_hours,
          element.comp_30,
          element.comp_50,
          element.comp_70,
          element.comp_100
        );
        renderObjects.push(
          <tbody
            key={element.date + " " + "ID:" + element.id}
            className="evenItemBody"
          >
            <tr className="evenItemRow">
              <td className="dateTable">
                <CertifiedCheckbox
                  element={element}
                  setCertifyArray={setCertifyArray}
                  checkedState={checkedState}
                  setCheckedState={setCheckedState}
                  handleRender={handleRender}
                />

                <EditableInput
                  editFields={editFields}
                  value={element.date}
                  type={"date"}
                  id={element.id}
                  handleRender={handleRender}
                />
              </td>
              <td>
                <div className="symbolContainer">
                  <StatusSymbol
                    bool={element.certified}
                    id={element.id}
                    handleRender={handleRender}
                    path={"time_reports"}
                  />
                  <EditableSymbol
                    bool={element.editable}
                    id={element.id}
                    handleRender={handleRender}
                  />
                </div>
              </td>
              <td className="userNameField">
                {isPressed ? (
                  <div className="deleteContainer">
                    <DeleteComponent
                      id={element.id}
                      handleRender={handleRender}
                    />{" "}
                    {element.user_name}
                  </div>
                ) : (
                  element.user_name
                )}
              </td>
              <td className="numbersTable">
                <StartTimeInput
                  editFields={editFields}
                  value={element.start_time}
                  stopTime={element.stop_time}
                  id={element.id}
                  breakTime={element.break_time}
                  handleRender={handleRender}
                />
              </td>
              <td className="numbersTable">
                <StopTimeInput
                  editFields={editFields}
                  value={element.stop_time}
                  startTime={element.start_time}
                  id={element.id}
                  breakTime={element.break_time}
                  handleRender={handleRender}
                />
              </td>
              <td className="numbersTable">
                <EditableInput
                  editFields={editFields}
                  value={element.break_time}
                  type={"break_time"}
                  id={element.id}
                  handleRender={handleRender}
                />
              </td>
              <td className="numbersTable">
                <EditableInput
                  editFields={editFields}
                  value={element.work_hours}
                  type={"work_hours"}
                  id={element.id}
                  handleRender={handleRender}
                />
              </td>
              <td className="numbersTable">
                <TotalTime
                  value={element.start_time}
                  value2={element.stop_time}
                  breakTime={element.break_time}
                  id={element.id}
                  addTime={addTime}
                />
              </td>
              <td>
                <ExportToggle
                  element={element}
                  collectExportArray={collectExportArray}
                  removeItemExportArray={removeItemExportArray}
                  handleRender={handleRender}
                  type={"time"}
                />
              </td>
              <td className="numbersTable">
                <EditableInput
                  editFields={editFields}
                  value={element.comp_30}
                  type={"comp_30"}
                  id={element.id}
                  handleRender={handleRender}
                />
              </td>
              <td className="numbersTable">
                <EditableInput
                  editFields={editFields}
                  value={element.comp_50}
                  type={"comp_50"}
                  id={element.id}
                  handleRender={handleRender}
                />
              </td>
              <td className="numbersTable">
                <EditableInput
                  editFields={editFields}
                  value={element.comp_70}
                  type={"comp_70"}
                  id={element.id}
                  handleRender={handleRender}
                />
              </td>
              <td className="numbersTable">
                <EditableInput
                  editFields={editFields}
                  value={element.comp_100}
                  type={"comp_100"}
                  id={element.id}
                  handleRender={handleRender}
                />
              </td>
              <td  className="numbersTable">
                <ToggleSwitch
                  bool={element.has_travel_expences}
                  editFields={editFields}
                  id={element.id}
                  type={"has_travel_expences"}
                  handleRender={handleRender}
                />
              </td>
              <td  className="numbersTable">
                <ToggleSwitch
                  bool={element.has_allowance}
                  editFields={editFields}
                  id={element.id}
                  type={"has_allowance"}
                  handleRender={handleRender}
                />
              </td>
              <td  className="numbersTable">
                <ToggleSwitch
                  bool={element.out_call}
                  editFields={editFields}
                  id={element.id}
                  type={"out_call"}
                  handleRender={handleRender}
                />
              </td>
              <td>
                <EditableInput
                  editFields={editFields}
                  value={element.note}
                  type={"note"}
                  id={element.id}
                  handleRender={handleRender}
                />
              </td>
              <td>
                <DropDownInput
                  editFields={editFields}
                  value={element.city}
                  type={"location"}
                  id={element.id}
                  handleRender={handleRender}
                  getOptionsFunction={getCities}
                />
              </td>

            </tr>
          </tbody>
        );
      } else {
        // rendering the odd rows
        sumateData(
          element.work_hours,
          element.comp_30,
          element.comp_50,
          element.comp_70,
          element.comp_100
        );

        renderObjects.push(
          <tbody
            key={element.date + " " + "ID:" + element.id}
            className="oddItemBody"
          >
            <tr className="oddItemRow">
              <td className="dateTable">
                <CertifiedCheckbox
                  element={element}
                  setCertifyArray={setCertifyArray}
                  checkedState={checkedState}
                  setCheckedState={setCheckedState}
                  handleRender={handleRender}
                />

                <EditableInput
                  editFields={editFields}
                  value={element.date}
                  type={"date"}
                  id={element.id}
                  handleRender={handleRender}
                />
              </td>
              <td>
                <div className="symbolContainer">
                  <StatusSymbol
                    bool={element.certified}
                    id={element.id}
                    handleRender={handleRender}
                    path={"time_reports"}
                  />
                  <EditableSymbol
                    bool={element.editable}
                    id={element.id}
                    handleRender={handleRender}
                  />
                </div>
              </td>
              <td className="userNameField">
                {isPressed ? (
                  <div className="deleteContainer">
                    <DeleteComponent
                      id={element.id}
                      handleRender={handleRender}
                    />{" "}
                    {element.user_name}
                  </div>
                ) : (
                  element.user_name
                )}
              </td>
              <td>
                <StartTimeInput
                  editFields={editFields}
                  value={element.start_time}
                  stopTime={element.stop_time}
                  id={element.id}
                  breakTime={element.break_time}
                  handleRender={handleRender}
                />
              </td>
              <td>
                <StopTimeInput
                  editFields={editFields}
                  value={element.stop_time}
                  startTime={element.start_time}
                  id={element.id}
                  breakTime={element.break_time}
                  handleRender={handleRender}
                  updateActiveData={updateActiveData}
                />
              </td>
              <td>
                <EditableInput
                  editFields={editFields}
                  value={element.break_time}
                  type={"break_time"}
                  id={element.id}
                  handleRender={handleRender}
                />
              </td>
              <td>
                <EditableInput
                  editFields={editFields}
                  value={element.work_hours}
                  type={"work_hours"}
                  id={element.id}
                  handleRender={handleRender}
                />
              </td>
              <td>
                <TotalTime
                  value={element.start_time}
                  value2={element.stop_time}
                  breakTime={element.break_time}
                  id={element.id}
                  addTime={addTime}
                />
              </td>
              <td>
                <ExportToggle
                  element={element}
                  collectExportArray={collectExportArray}
                  removeItemExportArray={removeItemExportArray}
                  handleRender={handleRender}
                  type={"time"}
                />
              </td>
              <td>
                <EditableInput
                  editFields={editFields}
                  value={element.comp_30}
                  type={"comp_30"}
                  id={element.id}
                  handleRender={handleRender}
                />
              </td>
              <td>
                <EditableInput
                  editFields={editFields}
                  value={element.comp_50}
                  type={"comp_50"}
                  id={element.id}
                  handleRender={handleRender}
                />
              </td>
              <td>
                <EditableInput
                  editFields={editFields}
                  value={element.comp_70}
                  type={"comp_70"}
                  id={element.id}
                  handleRender={handleRender}
                />
              </td>
              <td>
                <EditableInput
                  editFields={editFields}
                  value={element.comp_100}
                  type={"comp_100"}
                  id={element.id}
                  handleRender={handleRender}
                />
              </td>
              <td>
                <ToggleSwitch
                  bool={element.has_travel_expences}
                  editFields={editFields}
                  id={element.id}
                  type={"has_travel_expences"}
                  handleRender={handleRender}
                />
              </td>
              <td>
                <ToggleSwitch
                  bool={element.has_allowance}
                  editFields={editFields}
                  id={element.id}
                  type={"has_allowance"}
                  handleRender={handleRender}
                />
              </td>
              <td>
                <ToggleSwitch
                  bool={element.out_call}
                  editFields={editFields}
                  id={element.id}
                  type={"out_call"}
                  handleRender={handleRender}
                />
              </td>
              <td>
                <EditableInput
                  editFields={editFields}
                  value={element.note}
                  type={"note"}
                  id={element.id}
                  handleRender={handleRender}
                />
              </td>
              <td>
                <DropDownInput
                  editFields={editFields}
                  value={element.city}
                  type={"location"}
                  id={element.id}
                  handleRender={handleRender}
                  getOptionsFunction={getCities}
                />
              </td>

            </tr>
          </tbody>
        );
      }
      index++;
    });
    return renderObjects;
  };

  useEffect(() => {
    const fetchData = async () => {
      // Create a new Date object based on the value of 'startDate'.
      const newEndDate = new Date(startDate);

      newEndDate.setDate(newEndDate.getDate());

      // Create a new Date object for the current date.
      const newStartDate = new Date();

      newStartDate.setDate(newStartDate.getDate());

      let data = await fetchUserData(
        newStartDate.toISOString().slice(0, 10),
        newEndDate.toISOString().slice(0, 10),
        requestDataArray
      );

      // Filter the fetched data to keep only the items where 'certified' is equal to 1.
      const filteredData = await data.filter(
        (item) => parseInt(item.certified) === 1
      );

      // Update the state with the filtered data.
      setExportData(await filteredData);

      // Indicate that the loading process is completed.
      setIsLoading(false);

      // Fetch all user names.
      const usersData = await getAllUserNames();

      // Initialize an array with a default user selection.
      const userArray = [{ value: 0, label: "All" }];

      // Update the state with the entire fetched data.
      setActiveData(await data);

      // Iterate over the fetched user names and structure them in a specific format.
      await usersData.map((user) => {
        // Create a object with the user id as key and the full name as value.
        const userObject = {
          value: user.ID,
          label: user.full_name,
          employee_number: user.employee_number,
          employee_employment: user.employee_employment,
        };

        // Add the structured user object to the user array.
        userArray.push(userObject);
      });

      // Update the state with the formatted user array.
      setUsers(userArray);
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      // Create a new Date object based on the value of 'startDate'.
      const newStartDate = new Date(startDate);

      // Fetch user data between 'newStartDate' and 'endDate'.
      let data = await fetchUserData(
        newStartDate.toISOString().slice(0, 10),
        endDate,
        requestDataArray
      );

      // Fetch entire week's time reports between 'newStartDate' and 'endDate'.
      let entireWeek = await fetchEntireWeeksTimeReports(
        newStartDate.toISOString().slice(0, 10),
        endDate,
        requestDataArray
      );

      // Update the state with the entire week's data.
      setEntireWeekData(await entireWeek);

      // Calculate start and end index for pagination.
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = startIndex + pageSize;

      // Slice the data to get the current page's data.
      const slicedData = data.slice(startIndex, endIndex);

      // Fetch all user names.
      const usersData = await getAllUserNames();

      // Initialize an array with a default user selection.
      const userArray = [{ value: 0, label: "All" }];

      // Format and push each user data into the 'userArray'.
      await usersData.map((user) => {
        const userObject = {
          value: user.ID,
          label: user.full_name,
          employee_number: user.employee_number,
          employee_employment: user.employee_employment,
        };
        userArray.push(userObject);
      });

      // Update the state with the structured user array.
      setUsers(userArray);

      // Process sliced data with the 'TimeReportsComponent'.
      const workItems = await TimeReportsComponent(slicedData, userArray);

      // Update the state with current page's data.
      setCurrentPageData(await slicedData);

      // Update the state with the processed work items.
      setWorkItems(workItems);

      // Calculate and set the total number of pages based on the data size.
      setTotalPages(Math.ceil(data.length / pageSize));

      // If the total pages are less than or equal to 10, show all page numbers.
      // Otherwise, show a reduced set of page numbers with ellipses for navigation.
      if (totalPages <= 10) {
        setDisplayedPages(Array.from({ length: totalPages }, (_, i) => i + 1));
      } else {
        setDisplayedPages([1, 2, 3, 4, 5, "...", totalPages - 1, totalPages]);
      }

      // Update the state with the entire data fetched.
      setTimeReports(await data);

      // Set the total number of exported data items.
      setTotalNumberOfExports(data.length);

      // Filter the data to retain only items where 'certified' is equal to 1.
      const filteredData = await data.filter(
        (item) => parseInt(item.certified) === 1
      );

      // Update the state with the number of filtered data items.
      setNumberOfExports(filteredData.length);

      setExportData(filteredData);

      setActiveData(await data);
    };

    fetchData();
  }, [startDate, endDate]);

  useEffect(() => {
    const fetchData = async () => {
      // Convert the 'startDate' to a Date object.
      const newStartDate = new Date(startDate);

      // Fetch user data for the given date range and request data.
      let data = await fetchUserData(
        newStartDate.toISOString().slice(0, 10),
        endDate,
        requestDataArray
      );

      // Create a map of item IDs to their indices in the data array.
      const indexMap = data.reduce((map, item, index) => {
        map[item.id] = index;
        return map;
      }, {});

      // Sort data based on the order of IDs using the indexMap.
      data.sort((a, b) => indexMap[a.id] - indexMap[b.id]);

      // Apply a sorting function to the data based on the 'activeSorting' and 'ascend' criteria.
      data = sortingFunction(data, activeSorting, ascend, users);

      // Determine the start and end indices for pagination.
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = startIndex + pageSize;

      // Slice the data to obtain the records for the current page.
      const slicedData = data.slice(startIndex, endIndex);

      // Fetch a list of all user names.
      const usersData = await getAllUserNames();

      // Initialize the userArray with a default selection option.
      const userArray = [{ value: 0, label: "All" }];

      // Populate the userArray with formatted user data.
      await usersData.map((user) => {
        const userObject = {
          value: user.ID,
          label: user.full_name,
          employee_number: user.employee_number,
          employee_employment: user.employee_employment,
        };
        userArray.push(userObject);
      });

      // Update the state with the complete data set.
      setActiveData(data);

      // Set the structured user data to state.
      setUsers(userArray);

      // Process the sliced data using the 'TimeReportsComponent' function.
      const workItems = await TimeReportsComponent(slicedData, userArray);

      // Update state with the processed work items.
      setWorkItems(workItems);

      // Update state with data for the current page.
      setCurrentPageData(slicedData);

      // Calculate the total number of pages based on the data size.
      const numberOfPages = Math.ceil(data.length / pageSize);
      setTotalPages(numberOfPages);

      // Set the pages to be displayed in the pagination component.
      if (numberOfPages <= 10) {
        setDisplayedPages(
          Array.from({ length: numberOfPages }, (_, i) => i + 1)
        );
      } else {
        setDisplayedPages([
          1,
          2,
          3,
          4,
          5,
          "...",
          numberOfPages - 1,
          numberOfPages,
        ]);
      }

      // Reset currentPage to 1 if it exceeds the total number of pages.
      if (currentPage > numberOfPages) {
        setCurrentPage(1);
      }

      setAbsenceReports(data);

      // Set the total number of records to state.
      setTotalNumberOfExports(data.length);

      // Filter out records where 'certified' is not equal to 1.
      const filteredData = data.filter(
        (item) => parseInt(item.certified) === 1
      );

      setNumberOfExports(filteredData.length);
      setExportData(filteredData);

      setTimeReports(data);
    };

    // Invoke the fetchData function.
    fetchData();
  }, [requestDataArray, currentPage, updateRender, pageSize]);



  const updateTable = (selectedUser) => {
    setRequestDataArray([selectedUser, "", "", ""]);
    setIsPressed(false);
    setSelectedUser(selectedUser);
    userFilter(requestDataArray);
    collectExportArray(exportData);
    handleRender();
  };
  // ** HEADER AND FOOTER RENDERING AREA ** //
  return (
    <div className="mainContainer">
      <div className="HeaderContainer">
        <div className="Header">
          <header>Närvaro</header>
        </div>
        <div className="Tab"></div>
      </div>
      <div className="subHeaderContainer">
        <DateFilter
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            handleRender={handleRender}
        />
        <UserSelect
            users={users}
            requestDataArray={requestDataArray}
            setRequestDataArray={setRequestDataArray}
            userFilter={userFilter}
            setSelectedUser={setSelectedUser}
            handleRender={handleRender}
        />


        <div className="buttonContainer">
          <ExportButton
            exportData={exportData}
            usersList={users}
            entireWeekData={entireWeekData}
            requestDataArray={requestDataArray}
            path={"timereports"}
            startDate={startDate}
            endDate={endDate}
            selectedUser={selectedUser}
          />
          <button
            className="button"
            onClick={() =>
              // setRequestDataArray(["", "", "", ""]) +
              setIsPressed(false) +
              // setSelectedUser(0) +
              userFilter(requestDataArray) +
              // collectExportArray(exportData) +
              handleRender()
            }
          >
            <FontAwesomeIcon icon={faRotate} size="lg" color="white" />
          </button>

          <button
            className="button"
            onClick={() => enableEditFields() + handleIsPressed()}
          >
            <FontAwesomeIcon
              icon={faPenToSquare}
              size="lg"
              color={isPressed ? "#FEDC46" : "white"}
            />
          </button>
          {isPressed ? (
            <button
              className="button"
              onClick={() =>
                enableEditFields() + handleIsPressed() + setIsVisible(true)
              }
            >
              <FontAwesomeIcon icon={faPlus} size="lg" color="white" />
            </button>
          ) : null}
          <NewReportModal
            isVisible={isVisible}
            toggleModal={toggleModal}
            selectedUser={selectedUser}
            users={users}
            type={"timereport"}
            updateTable={updateTable}
          />
        </div>
      </div>
      <table className="tableContainer">
      
        <thead className="tableHead">
          <tr className="tableRow">
            <th
              onClick={() =>
                setActiveSorting("date") + setAscend(!ascend) + handleRender()
              }
            >
              DATUM
            </th>
            <th
              onClick={() =>
                setActiveSorting("certified") +
                setAscend(!ascend) +
                handleRender()
              }
            >
              STATUS
            </th>
            <th
              className="userNameField"
              onClick={() =>
                setActiveSorting("user") + setAscend(!ascend) + handleRender()
              }
            >
              ANVÄNDARE
            </th>
            <th
              onClick={() =>
                setActiveSorting("start") + setAscend(!ascend) + handleRender()
              }
            >
              STARTTID
            </th>
            <th
              onClick={() =>
                setActiveSorting("stop") + setAscend(!ascend) + handleRender()
              }
            >
              SLUTTID
            </th>
            <th
              onClick={() =>
                setActiveSorting("break") + setAscend(!ascend) + handleRender()
              }
            >
              RAST (min)
            </th>
            <th
              onClick={() =>
                setActiveSorting("work_hours") +
                setAscend(!ascend) +
                handleRender()
              }
            >
              TIMMAR
            </th>
            <th>TOTAL TID</th>
            <th
              onClick={() =>
                setActiveSorting("export") + setAscend(!ascend) + handleRender()
              }
            >
              EXPORT
            </th>
            <th
              onClick={() =>
                setActiveSorting("comp_30") +
                setAscend(!ascend) +
                handleRender()
              }
            >
              ÖT 16-17
            </th>
            <th
              onClick={() =>
                setActiveSorting("comp_50") +
                setAscend(!ascend) +
                handleRender()
              }
            >
              ÖT 17-19
            </th>
            <th
              onClick={() =>
                setActiveSorting("comp_70") +
                setAscend(!ascend) +
                handleRender()
              }
            >
              ÖT 19-22
            </th>
            <th
              onClick={() =>
                setActiveSorting("comp_100") +
                setAscend(!ascend) +
                handleRender()
              }
            >
              ÖT 22-05
            </th>
            <th
              onClick={() =>
                setActiveSorting("travel") + setAscend(!ascend) + handleRender()
              }
            >
              RESA
            </th>
            <th
              onClick={() =>
                setActiveSorting("allowance") +
                setAscend(!ascend) +
                handleRender()
              }
            >
              TRAKT
            </th>
            <th
              onClick={() =>
                setActiveSorting("outcall") +
                setAscend(!ascend) +
                handleRender()
              }
            >
              UTR
            </th>
            <th
              onClick={() =>
                // setActiveSorting("location") +
                // setAscend(!ascend) +
                handleRender()
              }
            >
              NOTERING
            </th>
            <th
              onClick={() =>
                setActiveSorting("location") +
                setAscend(!ascend) +
                handleRender()
              }
            >
              ORT
            </th>

          </tr>
        </thead>
        <thead>
          <tr>
            <td>
              <CertifyButton
                path="timereport"
                activeData={activeData}
                checkedState={checkedState}
                setCheckedState={setCheckedState}
                currentPageData={currentPageData}
                handleRender={handleRender}
                certifyArray={certifyArray}
                setCertifyArray={setCertifyArray}
              >
                Certifiera
              </CertifyButton>
            </td>
            {/* sumate the rows in the footer */}
            <td colSpan="1"></td>
            <td colSpan="1"></td>
            <td colSpan="1"></td>
            <td colSpan="1"></td>
            <td colSpan="1"></td>
            <td colSpan="1"></td>
            <td className="col-footer" colSpan="1">
            </td>
            <td className="col-footer" colSpan="1">
            </td>
            <td className="col-footer" colSpan="1">
            </td>
            <td className="col-footer" colSpan="1">
            </td>
            <td className="col-footer" colSpan="1">
            </td>
            <td className="col-footer" colSpan="1">
            </td>
            <td colSpan="1"></td>
            <td colSpan="1"></td>
            <td colSpan="1"></td>
            <td colSpan="1"></td>
            <td colSpan="1"></td>
          </tr>
        </thead>
        
        {isLoading ? (
          <tbody>
            <tr>
              <td>loading</td>
            </tr>
          </tbody>
        ) : (
          workItems
        )}
        <tfoot>
          <tr>
            <td>
              <CertifyButton
                path="timereport"
                activeData={activeData}
                checkedState={checkedState}
                setCheckedState={setCheckedState}
                currentPageData={currentPageData}
                handleRender={handleRender}
                certifyArray={certifyArray}
                setCertifyArray={setCertifyArray}
              >
                Certifiera
              </CertifyButton>
            </td>
            {/* sumate the rows in the footer */}
            <td colSpan="1"></td>
            <td colSpan="1"></td>
            <td colSpan="1"></td>
            <td colSpan="1"></td>
            <td colSpan="1"></td>
            <td colSpan="1"></td>
            <td className="col-footer" colSpan="1">
              {sumatedWorkHours[0]}
            </td>
            <td className="col-footer" colSpan="1">
              {numberOfExports + "/" + totalNumberOfExports}
            </td>
            <td className="col-footer" colSpan="1">
              {sumatedWorkHours[1]}
            </td>
            <td className="col-footer" colSpan="1">
              {sumatedWorkHours[2]}
            </td>
            <td className="col-footer" colSpan="1">
              {sumatedWorkHours[3]}
            </td>
            <td className="col-footer" colSpan="1">
              {sumatedWorkHours[4]}
            </td>
            <td colSpan="1"></td>
            <td colSpan="1"></td>
            <td colSpan="1"></td>
            <td colSpan="1"></td>
            <td colSpan="1"></td>
          </tr>
        </tfoot>
      </table>

      <div className="paginationContainer">
        <div className="paginationNavigation">
          <button
            className="paginationNavigationButton"
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1}
          >
            {"<<"}
          </button>
          <button
            className="paginationNavigationButton"
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            {"<"}
          </button>
          {displayedPages.map((pageNumber, index) => {
            return pageNumber === "..." ? (
              <span key={index}>{pageNumber}</span>
            ) : (
              <button
                key={index}
                className="paginationLink"
                onClick={() => setCurrentPage(pageNumber)}
                // make the active page stand out
                style={{
                  fontWeight: pageNumber === currentPage ? "700" : "600",
                  color: pageNumber === currentPage ? "black" : "#ADB6C7",
                }}
              >
                {pageNumber}
              </button>
            );
          })}
          <button
            className="paginationNavigationButton"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            {">"}
          </button>
          <button
            className="paginationNavigationButton"
            onClick={() => setCurrentPage(totalPages)}
            disabled={currentPage === totalPages}
          >
            {">>"}
          </button>
        </div>
        <div className="paginationTotalLines">
          <p style={{ fontSize: 13, marginRight: 2, fontWeight: 700 }}>
            Antal rader:
          </p>
          <p style={{ color: "black", fontSize: 13, fontWeight: 700 }}>
            {" "}
            {totalNumberOfExports}
          </p>
        </div>
      </div>
    </div>
  );
}

export default TimeReports;
