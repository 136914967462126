// CustomToolbar.js
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faArrowRight,
    faCalendar,
    faCalendarDay,
    faClockRotateLeft,
    faMugHot
} from '@fortawesome/free-solid-svg-icons';

const CalendarToolbarPrintable = (toolbar) => {
    const label = () => {
        const date = toolbar.date;
        const view = toolbar.view;
        return view === 'month' ? date.toLocaleDateString('default', { month: 'long', year: 'numeric' }) : '';
    };

    return (
        <div className="rbc-toolbar">
      <span className="rbc-btn-group">
      </span>
            <span className="rbc-toolbar-label">{label()}</span>
        </div>
    );
};

export default CalendarToolbarPrintable;
